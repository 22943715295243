import * as React from "react"
import { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout"
import Seo from "../components/seo"
import '../styles/hakkimizda.css';
import aboutImage from '../images/about-image.png'
import { useTranslation } from 'gatsby-plugin-react-i18next';

const About = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.querySelector("body").classList.add("about");
    document.querySelector("header").classList.add("about");
  })
  return (
    <Layout>
      <Seo title={t("HakkimizdaSEO")} />
      <div className="mt-40 relative flex items-center justify-center md:mt-28">
        <div className="max-w-full overflow-hidden wrapper">
          <div className="inline-block overflow-hidden whitespace-nowrap animate-marquee">
            <p className="inline-block font-thin leading-none cursor-default font-third text-loop xl:text-9xl md:text-7xl text-defaultGold md:mb-0">{t("HakkimizdaP")}</p>
            <p className="inline-block font-thin leading-none cursor-default font-third text-loop xl:text-9xl md:text-7xl text-defaultGold md:mb-0">{t("HakkimizdaP")}</p>
            <p className="inline-block font-thin leading-none cursor-default font-third text-loop xl:text-9xl md:text-7xl text-defaultGold md:mb-0">{t("HakkimizdaP")}</p>
          </div>
        </div>
        <h1 className="mb-0 font-first text-default text-5xl font-semibold absolute m-auto md:text-3xl -mt-10 md:-mt-1">{t("HakkimizdaP")}</h1>
      </div>
      <div className="flex w-10/12 justify-between items-center mx-auto my-5 md:flex-col">
        <div className="flex flex-col items-start w-6/12 md:w-full md:mb-10">
          <h3 className="mb-10 text-defaultYellow relative font-normal text-5xl font-first pb-6 before:absolute before:bottom-0 before:left-0 md:before:right-0 before:h-[6px] before:w-8/12 before:bg-default md:text-center md:w-full md:flex md:justify-center md:before:mx-auto md:text-3xl md:before:h-1 md:pb-4 md:mb-6">{t("NedenBiz")}</h3>
          <p className="font-second text-[28px] text-defaultGray w-11/12 leading-10 md:leading-7 md:w-full md:text-lg">{t("HakkimizdaText1")}
          </p>
          <p className="font-second text-[28px] text-defaultGray w-11/12 leading-10 md:leading-7 md:w-full md:text-lg">
            {t("HakkimizdaText2")}</p>
          <div className="font-first text-default text-xl relative mt-5 w-11/12 pr-5 before:bg-default before:right-36 before:h-[1.5px] before:w-16 before:absolute flex items-center justify-end md:pr-0 md:before:right-32">Merve Genan</div>
        </div>
        <div className="w-6/12 md:w-full"><img src={aboutImage} alt="about" className="w-full" /></div>
      </div>
    </Layout>
  )
}

export default About;

export const query = graphql`
query ($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`;